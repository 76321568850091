<template>
  <div class="md:flex-between gap-24px items-stretch">
    <div class="rounded-24px mb-18px bg-white overflow-hidden flex-1 flex-col flex">
      <nuxt-img provider="twicpics" loading="lazy" class="w-full" src="/imgs/home/plugin.png" alt="arvin"></nuxt-img>
      <div class="px-24px mt-24px mb-16px fs-22 font-600 c-deep-90 leading-29px">Get the free Edge and Chrome extension for instant efficiency</div>
      <p class="px-24px fs-18 leading-24px c-deep-70">Boost your browsing experience with instant AI assistance—edit, search, and automate directly from the browser.</p>
      <div class="px-24px fs-20 font-500 mt-30px mb-36px leading-24px flex items-center cp c-primary hover:opacity-85 flex-1" style="align-items: self-end" size="large" @click="downloadArvin()">Add to {{ browser==='Edge'?browser:'Chrome' }}
        <img src="~/assets/imgs/arrow.png" class="ml-8px w-18px h-18px c-primary" alt="arvin" />
      </div>
    </div>
    <div class="rounded-24px mb-18px bg-white overflow-hidden flex-1 flex-col flex">
      <nuxt-img provider="twicpics" loading="lazy" class="w-full" src="/imgs/home/browser.png" alt="arvin"></nuxt-img>
      <div class="px-24px mt-24px mb-16px fs-22 font-600 c-deep-90 leading-29px">Your all-access web-based AI assistant</div>
      <p class="px-24px fs-18 leading-24px c-deep-70">Comprehensive AI web platform—accessible anytime, anywhere, on any device!</p>
      <div class="px-24px fs-20 font-500 mt-30px mb-36px leading-24px flex items-center cp c-primary hover:opacity-85 flex-1" style="align-items: self-end" @click="goToWebapp">Try Today
        <img src="~/assets/imgs/arrow.png" class="ml-8px w-18px h-18px c-primary" alt="arvin" />
      </div>
    </div>
    <div class="rounded-24px mb-18px bg-white overflow-hidden flex-1 flex-col flex">
      <nuxt-img provider="twicpics" loading="lazy" class="w-full" src="/imgs/home/mobile.png" alt="arvin"></nuxt-img>
      <div class="px-24px mt-24px mb-16px fs-22 font-600 c-deep-90 leading-29px">Your pocket mobile assistant for smart convenience</div>
      <p class="px-24px fs-18 leading-24px c-deep-70">
        Stay organised with AI-powered tools on your phone—they're smart, easy and always ready!</p>
      <div class="px-24px fs-20 font-500 mt-30px mb-36px leading-24px flex c-deep-90 opacity-0 flex-1" style="align-items: self-end">Try on the mobile
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
	const browser = getBrowserName();

	const goToWebapp = async () => {
		await reportEvent('Webapp_Click', {
			entry_type: 'website_homepage_instruction'
		});
		window.open('/en/home', '_blank');
	};
</script>
