<template>
  <div class="home-container pt-64px">
    <SafeAreaContainer class="lg:pt-36px">
      <section
        class="mb-0! lg:mb-160px!" flex
        flex-col items-center
        lg:flex-row lg:justify-between
        relative z-2
        lg:gap-20px>
        <div>
          <h1 class="text-center lg:text-left text-36px sm:text-48px font-700 leading-normal mb-36px c-#282A37">
            All-in-one<br>
            <span c-primary>AI assistant</span><br>
            Start with Arvin
          </h1>
          <div class="mb-36px fs-14 sm:fs-16 leading-none text-center lg:text-left">
            <p mb-16px>🤖 Powered by GPT-4o, Claude 3.5, Gemini 1.5, Llama 3.1, SD, DALL-E 3</p>
            <p mb-16px>🚀 10x boost: AI-enhanced chat, writing, art & search</p>
            <p>🧩 Extension for instant creating & more on any site</p>
          </div>
          <div class="relative">
            <HomeDownloadBtnGroup class="flex flex-col gap-12px items-center lg:items-start"/>
            <HomeAnimationArrow class="hidden lg:block top--80px right--80px" />
          </div>

          <div class="justify-center lg:justify-start flex gap-12px mt-16px items-center">
            <span class="font-500 c-#287941 text-14px sm:text-18px flex items-center gap-4px mr-12px">
              <nuxt-icon name="featured" class="text-18px sm:text-24px"></nuxt-icon>
              Featured
            </span>
            <div flex gap-6px sm:gap-8px>
              <nuxt-icon v-for="i in 5" :key="i" name="star-fill" class="c-#FFB600 text-14px sm:text-20px"></nuxt-icon>
            </div>
            <span class="font-500 text-14px sm:text-18px c-#282A37 ">4.9 ratings</span>
          </div>
        </div>
        <div class="first-screen-animation hidden lg:block w-611px h-500px relative">
          <ClientOnly>
            <div class="absolute w-290px left-40px top-280px" data-aos="zoom-in" data-aos-duration="200">
              <nuxt-img provider="twicpics" loading="lazy" alt="arvin" src="/imgs/home/card1.png" class="w-full rotate--4deg" />
            </div>
            <div
              class="absolute w-282px right-18px top-220px" data-aos="zoom-in"
              data-aos-delay="250"
              data-aos-duration="200">
              <nuxt-img provider="twicpics" loading="lazy" alt="arvin" src="/imgs/home/card2.png" class="last-one w-full rotate-6deg" />
            </div>
            <div
              class="absolute w-240px right-34px top-28px" data-aos="zoom-in"
              data-aos-delay="500"
              data-aos-duration="200">
              <nuxt-img provider="twicpics" loading="lazy" alt="arvin" src="/imgs/home/card3.png" class="w-full rotate--3deg" />
            </div>
            <div
              class="absolute w-320px left-20px top-40px cursor-pointer" data-aos="zoom-in"
              data-aos-delay="750" data-aos-duration="200"
              @click="showVideoPopup = true">
              <nuxt-img provider="twicpics" loading="lazy" alt="arvin" src="/imgs/home/card4.png" class="w-full rotate-4deg" />
              <div class="absolute w-full h-full left-0 top-0 flex-center rotate-4deg hover:opacity-80">
                <div class="absolute w-90px h-90px rounded-90px play-btn-outer"></div>
                <div class="absolute w-90px h-90px rounded-90px play-btn-inner"></div>
                <div class="w-90px h-90px rounded-90px bg-black flex-center play-animation">
                  <nuxt-icon name="play" :size="30" class="c-white text-30px"></nuxt-icon>
                </div>
              </div>
            </div>
          </ClientOnly>
        </div>
        <div class="lg:hidden relative">
          <div class="w-100vw" @click="showVideoPopup = true">
            <nuxt-img provider="twicpics" loading="lazy" alt="arvin" src="/imgs/home/header-picture.png" class="w-full lg:hidden" />
          </div>
        </div>
      </section>
      <section>
        <h2>Simply start using on any website</h2>
        <HomeStartUsingPlatform />
      </section>
      <section>
        <h2>How Arvin Works</h2>
        <HomeHowWorks />
      </section>
      <section>
        <h2>More tools available…</h2>
        <div
          class="max-w-1200px mx-auto mt-24px grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12px sm:gap-24px">
          <nuxt-link
            v-for="(item, index) in moreTools" :key="index"
            :to="item.link"
            :class="{ 'hover:bg-#F3F3FF cursor-pointer': item.link }"
            class="w-full bg-#fff rounded-16px flex-column-center p-12px sm:p-24px decoration-none">
            <div class="w-48px h-48px bg-#f7f8fa rounded-16px flex-center mb-8px ">
              <svg-icon :name="item.icon" size="24" class="c-#444658"></svg-icon>
            </div>
            <h3 class="fs-14 sm:fs-18 c-#444658 font-600 mb-6px sm:mb-12px">{{ item.name }}</h3>
            <p class="fs-12 sm:fs-14 leading-20px c-#828499 text-center">{{ item.desc }}</p>
          </nuxt-link>
          <div class="bg-#f3f3ff w-full rounded-16px flex-column-center p-24px">
            <div class="w-48px h-48px bg-#f7f8fa rounded-16px flex-center mb-8px ">
              <svg-icon name="code2" size="24" class="c-#444658"></svg-icon>
            </div>
            <p class="fs-18 font-600 c-#444658 text-center">More features are being developed...</p>
          </div>
        </div>
      </section>
      <section>
        <h2 class="mb-16px! pb-0!">Real User Reviews</h2>
        <div class="c-#828499 fs-16 text-center mb-60px">Arvin is used by users all over the world</div>
        <HomeUserReview />
      </section>
      <section flex-column-center>
        <h2 class="mb-20px!">Frequently Asked Questions</h2>
        <div class="w-full max-w-840px">
          <NewFAQ title="" :list="FAQList">
          </NewFAQ>
        </div>
      </section>
      <FooterAdv/>
    </SafeAreaContainer>
    <BackToTop />
    <el-dialog v-model="showVideoPopup" class="video-popup" width="65%" :destroy-on-close="true">
      <iframe
        class="rounded-16px overflow-hidden aspect-16/9" width="100%"
        height="100%"
        src="https://www.youtube.com/embed/tqRDYhHYCco?si=zwvvj4qncHzYyjZ5&autoplay=1"
        title="Arvin - Your AI Writing Assistant & Best AI Content Generator" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
      <div
        class="hidden sm:flex w-40px h-40px rounded-40px flex-center absolute top--46px right--46px cp"
        border="1px solid white"
        @click="showVideoPopup = false">
        <nuxt-icon name="close" class="c-white fs-24"></nuxt-icon>
      </div>
    </el-dialog>
  </div>
</template>
<script setup lang="ts">
	import useFromTo from '@/composables/useFromTo';
	const title = 'Arvin AI - Best ChatGPT Chrome Extension Powered by GPT-4o ';
	const description = 'Arvin AI: A free ChatGPT Chrome extension powered by GPT-4o, offering you AI assistant for AI art, summaries, and content creation. Try now! ';
	const keywords = 'arvin ai, tryarvin ai, arvin ai art generator';

	useFromTo();
	useSeoMeta({
		title,
		keywords,
		ogTitle: title,
		description,
		ogDescription: description
	});

	const showVideoPopup = ref(false);

	const moreTools = [
		{
			name: 'Chat with AI',
			desc: 'Get the fastest GPTs responses',
			link: '/feature/how-to-access-gpt-4',
			icon: 'chat'
		},
		{
			name: 'AI Writer',
			desc: 'Quickly generate articles, emails, blogs, etc.',
			link: '/feature/ai-article-writer',
			icon: 'write'
		},
		{
			name: 'AI Image Generator',
			desc: 'Generate pictures in various styles with one click',
			link: '/feature/ai-painter',
			icon: 'brush'
		},
		{
			name: 'Data Analysis',
			desc: 'Complex data and coding processing through conversation',
			link: '/arvin-code-interpreter',
			icon: 'explain-code'
		},
		{
			name: 'Chat with PDF',
			desc: 'Summarize PDF content precisely',
			link: '',
			icon: 'pdf'
		},
		{
			name: 'Email Reply',
			desc: 'Generate multiple styles of email responses',
			link: '/feature/ai-email-response-generator',
			icon: 'message'
		},
		{
			name: 'Youtube Summary',
			desc: 'Generate YouTube summary online for free',
			link: '',
			icon: 'youtube'
		}, {
			name: 'Area Summary',
			desc: 'Summarize the content of each area of the web page online',
			link: '',
			icon: 'website'
		}, {
			name: 'Background Remover',
			desc: 'Remove image backgrounds automatically',
			link: '/image-tools/background-remover',
			icon: 'bg-remover'
		}, {
			name: 'Background Changer',
			desc: 'Effortlessly change image backgrounds',
			link: '/image-tools/background-changer',
			icon: 'bg-changer'
		}, {
			name: 'Sketch to Image',
			desc: 'Convert drawings into beautiful images',
			link: '/image-tools/sketch-to-image',
			icon: 'pen'
		}, {
			name: 'Image to Image',
			desc: 'Easily create variations of an image',
			link: '/image-tools/image-to-image',
			icon: 'img-to-img'
		}, {
			name: 'Image Replacer',
			desc: 'Remove objects and replace them',
			link: '/image-tools/ai-photo-editor',
			icon: 'image-replacer'
		},
		{
			name: 'Remove Text',
			desc: 'remove unwanted text while maintaining its original quality',
			link: '/image-tools/remove-text-from-image',
			icon: 'remove-text'
		},
		{
			name: 'Grammar Checker',
			desc: 'AI Grammar Checker at your fingertips',
			link: '/feature/ai-grammar-checker',
			icon: 'grammar'
		},
		{
			name: 'Writing Improver',
			desc: 'Polish Your Text Smoothly',
			link: '/feature/writing-improver',
			icon: 'pen'
		},
		{
			name: 'Web Translator',
			desc: 'Instantly translate any webpage',
			link: '/feature/web-translator',
			icon: 'translate'
		},

		{
			name: 'PDF to Word',
			desc: 'Convert your PDF to Word',
			link: '/PDF-tools/pdf-to-word',
			icon: 'word'
		},
		{
			name: 'PDF to PNG',
			desc: 'Convert your PDF to PNG',
			link: '/PDF-tools/pdf-to-png',
			icon: 'png'
		},
		{
			name: 'PDF to JPG',
			desc: 'Convert your PDF to JPG',
			link: '/PDF-tools/pdf-to-jpg',
			icon: 'jpg'
		}
	];

	const FAQList = [
		{ q: 'What is Arvin?', a: 'Arvin is your AI-powered ChatGPT assistant, a browser extension that saves time and money. It offers ChatGPT responses on Google, summarizes YouTube, blogs, and docs, and interacts on LinkedIn, Twitter, and Gmail. It translates into 25+ languages.' },
		{ q: 'How does Arvin work?', a: 'Arvin, powered by ChatGPT API, responds to chat, creates copy from templates, translates, rephrases, and explains web text.' },
		{ q: 'How to use Arvin?', a: "Once added to Chrome, open Arvin with Alt/Option + A. On sites like Twitter, LinkedIn, Youtube, and Gmail, you'll find Arvin buttons for easy access." },
		{ q: 'Is Arvin free?', a: 'Yes, Arvin is free and safe. Users receive 30 daily credits that can be used for various services including GPT 3.5, GPT-4o, YouTube Summarizer, web summarizer, PDF summarizer, and more.' },
		{
			q: 'Can I have a refund?', a: `For those who have signed up for a paid plan and wish to initiate a refund request, please contact us directly at contact@arvin.chat. Please note that refunds may take 5-10 business days to be processed.</br>
      </br>
      You are eligible for a refund only if you meet ALL of the following conditions simultaneously (for subscription types with a trial period, counting starts from the beginning of the trial):</br>
      </br>
      1. Your subscription period does not exceed 7 days；</br>
      2. You have not used advanced models more than 30 times；</br>
      3. You have not used drawing/image tools more than 10 times.</br>
      </br>
      We will review your actual usage to determine if you qualify for a refund. To prevent malicious refund requests, we reserve the right to decline refunds at our discretion.`
		}
		// { q: 'Do I need a ChatGPT account to use Arvin?', a: "No, a ChatGPT account isn't needed. You can create a free account ", link: '/user/dashboard' },
		// { q: 'Which search engine is supported by Arvin?', a: 'Arvin supports Google, Baidu, Bing, DuckDuckGo, Yahoo, Yandex, Ask, Naver, and Ecosia.' }
	];

	onMounted(() => {
		reportEvent('Web_Homepage_Show');
	});

</script>
<style lang="less" scoped>
.home-container {

  .first-screen-animation {
    img {
      border-radius: 16px;
      box-shadow: 19px 24px 40px 0px rgba(86, 86, 146, 0.1);

      &.last-one {
        box-shadow: 19px 24px 80px 0px rgba(86, 86, 146, 0.1);
      }
    }
  }

  .backdrop {
    backdrop-filter: blur(60px);
    background-color: #fff9;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0;
    color: #282A37;
    @apply sm:text-48px sm:mb-40px;
  }

  section {
    @apply mb-70px lg:150px;
  }

  .play-btn-outer {
    background: radial-gradient(#7c61cd 30%, #7c61cd 100%);
    transform-origin: center center;
    animation: play-btn-pulse-outer 4s ease-out infinite;
  }

  .play-btn-inner {
    background: radial-gradient(#7c61cd 30%, #7c61cd 100%);
    transform-origin: center center;
    animation: play-btn-pulse-inner 4s ease-out infinite;
  }

  .play-animation {
    transform-origin: center center;
    animation: play-btn-pulse 4s ease-out infinite;
  }

  @keyframes play-btn-pulse {

    0%,
    10%,
    to {
      transform: scale(.95)
    }

    30% {
      transform: scale(1)
    }
  }
}

@keyframes play-btn-pulse-inner {

  0%,
  30% {
    opacity: .3;
    transform: scale(1)
  }

  to {
    opacity: 0;
    transform: scale(1.8)
  }
}

@keyframes play-btn-pulse-outer {
  0% {
    opacity: .3;
    transform: scale(1)
  }

  80%,
  to {
    opacity: 0;
    transform: scale(2)
  }
}
</style>
