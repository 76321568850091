const firstFromTo = ref(true);

const useFromTo = () => {
  onBeforeMount(() => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    // login, first, no path
    const path = window.location.pathname;
		const token = getArvinSessionToken();

    console.log('path', path, token, firstFromTo.value, hasParamsUrl());
    if (firstFromTo.value && token && path === '/' && !hasParamsUrl()) {
		  window.location.href = '/en/home';
    }
    firstFromTo.value = false;
  });
};

const hasParamsUrl = () => {
  const currentUrl = window.location.href;
  return currentUrl.includes('?');
};
export default useFromTo;
