<template>
  <div
    v-for="(item, index) in howWorks"
    :key="index" class="flex flex-col items-center lg:flex-row gap-30px lg:gap-60px mb-60px lg:mb-120px"
    :class="{ 'lg:flex-row-reverse': index === 1 }">
    <div>
      <div class="flex gap-12px items-center mb-10px">
        <nuxt-img provider="twicpics" loading="lazy" alt="arvin" :src="item.icon" class="w-30px sm:w-58px" />
        <div class="fs-18 sm:fs-24 font-600 c-primary">{{ item.name }}</div>
      </div>
      <h3 class="fs-24 sm:fs-36 font-600 c-#282A37 mb-12px">{{ item.title }}</h3>
      <p class="fs-14 sm:fs-18 c-#828499 mb-24px">{{ item.desc }}</p>
      <div v-for="(_item, i) in item.list" :key="i" class="flex gap-8px items-start mb-12px sm:mb-24px">
        <svg-icon name="checked-options" :size="16" class="sm:pt-2px"></svg-icon>
        <p class="fs-14 sm:fs-18 c-#444658">{{ _item }}</p>
      </div>
      <a :href="downloadUrl" target="_blank" aria-label="download arvin" class="mx-auto lg:mx-0 flex-center w-280px h-42px lg:h-56px rounded-42px lg:rounded-56px bg-primary mt-18px lg:mt-36px c-white font-500 fs-18 decoration-none hover:opacity-80" @click="handleDownload">Get Started</a>
    </div>
    <nuxt-img provider="twicpics" loading="lazy" alt="arvin" class="w-full lg:hidden" :src="item.picture" />
    <div class="hidden lg:block w-680px h-510px flex-shrink-0 relative text-100px">
      <template v-if="index === 0">
        <nuxt-img
          provider="twicpics" loading="lazy"
          alt="arvin" src="/imgs/home/chat-animation/1.png"
          class="absolute left-2.46em bottom-0.28em w-2.9em"
          data-aos="fade-up" />
        <nuxt-img
          provider="twicpics" loading="lazy"
          alt="arvin" src="/imgs/home/chat-animation/3.png"
          class="absolute w-3.6em bottom--0.38em left--0.25em"
          data-aos="fade-up" data-aos-delay="200" />
        <nuxt-img
          provider="twicpics" loading="lazy"
          alt="arvin" src="/imgs/home/chat-animation/2.png"
          class="absolute w-1.51em bottom-0.28em right-0.2em"
          data-aos="fade-up" data-aos-delay="400" />
      </template>
      <template v-else-if="index === 1">
        <nuxt-img
          provider="twicpics" loading="lazy"
          alt="arvin" src="/imgs/home/write-animation/1.png"
          class="absolute left-0 bottom-0 top-0 w-5.38em"
          data-aos="fade-up"/>
        <nuxt-img
          provider="twicpics" loading="lazy"
          alt="arvin" src="/imgs/home/write-animation/2.png"
          class="absolute w-1.79em bottom-0.1em right-0.51em" data-aos="fade-up"
          data-aos-delay="200" />
      </template>
      <template v-else-if="index === 2">
        <nuxt-img
          provider="twicpics" loading="lazy"
          alt="arvin" src="/imgs/home/paint-animation/1.png"
          class="absolute right-0 bottom-0 w-4.27em right-0.76em"
          data-aos="fade-up"/>
        <nuxt-img
          provider="twicpics" loading="lazy"
          alt="arvin" src="/imgs/home/paint-animation/5.png"
          class="absolute w-2.23em left-0em bottom-0.1em"
          data-aos="fade-up" />
        <nuxt-img
          provider="twicpics" loading="lazy"
          alt="arvin" src="/imgs/home/paint-animation/3.png"
          class="absolute w-1.53em left-0.15em top-1.3em"
          data-aos="fade-up" data-aos-delay="1000" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
	const browserName = getBrowserName();
	const runtimeConfig = useRuntimeConfig();
	const { googleDownloadUrl, edgeDownloadUrl } = runtimeConfig.public;

	// const downloadUrl = browserName === 'Edge' ? edgeDownloadUrl : googleDownloadUrl;
	const downloadUrl = '/en/home';

	const howWorks = [
		{
			title: 'Chat casually with Arvin',
			desc: 'Get comprehensive, relevant, and accurate answers',
			list: [
				'Chat with GPT-4o, Claude 3.5, Gemini 1.5, Llama 3.1, etc.',
				'Chat with Arvin about uploaded PDFs, images, screenshots, and more',
				'Generate and execute code solutions through the Data Analysis to complete tasks such as coding, chart creation, etc'
			],
			picture: '/imgs/home/works-chat.png',
			name: 'Chat',
			icon: '/imgs/home/chat.png'
		},
		{
			title: 'Writing becomes easy',
			desc: 'Write up to 10x faster and boost your writing efficiency and quality.',
			list: [
				'Automatically generate articles, blogs, emails, essays, and anything else you might need',
				'Customize the style of email replies',
				'Multiple templates for anything: from blessing cards to travel guidance'
			],
			picture: '/imgs/home/works-write.png',
			name: 'Write',
			icon: '/imgs/home/write.png'
		},
		{
			title: 'Comprehensive AI Image Generation Tools',
			desc: 'Tell stories, explain design projects, and express ideas with generated images',
			list: [
				'Generate images by describing what you want to see',
				'Personalize the style and ratio of your images',
				'Replace backgrounds, sketch-to-image and other more comprehensive image editing tools'
			],
			picture: '/imgs/home/works-paint.png',
			name: 'Paint',
			icon: '/imgs/home/paint.png'
		}
	];

	function handleDownload () {
		reportEvent('Web_Homepage_Install_Btn_Click');
	}
</script>

<style lang="less" scoped></style>
